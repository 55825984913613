.notifications-wrapper{
  .notifications-br{
    font-family: inherit;
    position: fixed;
    width: 100%;
    padding: 0;
    z-index: 9999995;
    box-sizing: border-box;
    inset: auto 0px 0px auto;
    
  }
  .notification{
    position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 2px;
    margin: 10px 0 0 0;
    padding: 20px;
    display: block;
    box-sizing: border-box;
    opacity: 1;
    transition: all 0.3s ease-in-out 0s;
    transform: translate3d(0px, 0px, 0px);
    will-change: transform, opacity;
    background-color: #fff;
    color: #000;
    border-left: 4px solid #009CDE;
    right: 0px;
    height: auto !important;
    font-size: 1.125rem;
    box-shadow: #0d0f13 0px 4px 23px -11px;
  }
  .notification-title{
    text-transform: uppercase;
    font-family: $font-family-base;
    font-weight: 900;
    font-size: 2em;
    width: calc(100% - 24px);
    margin-bottom: 14px;
    color: #000;
  }
  .notification-dismiss{
    cursor: pointer;
    font-family: Arial;
    font-size: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
    line-height: 24px;
    background-color: #fff;
    color: #ef4918;
    border: 0.5px solid #ef4918;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    &:hover, &:focus{
      
      border: 0.5px solid #a33619;
      color: #a33619;
    }
  }
  .notification-action-button{
    padding: 6px 20px;
    margin: 10px 0px 0px;
    font-size: 14px;
    font-family: "BrandonGrotesque", Arial, sans-serif;
    cursor: pointer;
    color: #fff;
    background-color: #cf4520;
    border: 1px solid transparent;
    border-color: #cf4520;
    &:hover, &:focus{
      color: #fff;
      background-color: #ae3a1b;
      border-color: #a33619;
    }
  }
}

@media screen and (min-width: 991px) {
  .notifications-wrapper{
    .notifications-br{   
      max-width: 440px;
    }
    .notification{
      padding: 20px 32px;
      border-top: 0;
    }
    .notification-dismiss{
      right: 32px;
    }
  }
}