.disclaimers {
  padding: 20px 0;

  &#accordion {
    margin-top: 30px;
    h2 {
      font-family: 'BrandonGrotesque', sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.2;
      color: #222732;
    }
    .card {
      border: 0px;
    }
    .card-header {
      background: #ffd000;
      color: #222732;
      padding: 15px;
      border-radius: 0;
    }
    .card-body {
      background: #393d47;
      color: white;
      padding: 15px;

      ul {
        padding: 0;
        margin: 0;
        font-size: 15px;
        list-style: none;

        li {
          padding-bottom: 26px;
        }
      }
    }
  }
}
