.post-wrapper {
  margin: 1em 0;
}

.post.casestudies .page-content {
  table {
    margin-bottom: 2rem;
  }
}

.post-columns {
  padding-top: 2rem;

  .post-column {
    // padding-bottom: 3rem;

    &.left.col-md-6 {
      padding-right: 1.5rem;
    }
    &.right {
      padding-left: 1.5rem;
    }

    // &.col-md-12 {
    //   padding-bottom: 1rem;
    // }
  }

}
