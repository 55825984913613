.has-pale-pink-background-color.has-pale-pink-background-color {
  background-color: #f78da7; }

.has-vivid-red-background-color.has-vivid-red-background-color {
  background-color: #cf2e2e; }

.has-luminous-vivid-orange-background-color.has-luminous-vivid-orange-background-color {
  background-color: #ff6900; }

.has-luminous-vivid-amber-background-color.has-luminous-vivid-amber-background-color {
  background-color: #fcb900; }

.has-light-green-cyan-background-color.has-light-green-cyan-background-color {
  background-color: #7bdcb5; }

.has-vivid-green-cyan-background-color.has-vivid-green-cyan-background-color {
  background-color: #00d084; }

.has-pale-cyan-blue-background-color.has-pale-cyan-blue-background-color {
  background-color: #8ed1fc; }

.has-vivid-cyan-blue-background-color.has-vivid-cyan-blue-background-color {
  background-color: #0693e3; }

.has-very-light-gray-background-color.has-very-light-gray-background-color {
  background-color: #eee; }

.has-cyan-bluish-gray-background-color.has-cyan-bluish-gray-background-color {
  background-color: #abb8c3; }

.has-very-dark-gray-background-color.has-very-dark-gray-background-color {
  background-color: #313131; }
  
 
.has-pale-pink-color.has-pale-pink-color {
  color: #f78da7; }

.has-vivid-red-color.has-vivid-red-color {
  color: #cf2e2e; }

.has-luminous-vivid-orange-color.has-luminous-vivid-orange-color {
  color: #ff6900; }

.has-luminous-vivid-amber-color.has-luminous-vivid-amber-color {
  color: #fcb900; }

.has-light-green-cyan-color.has-light-green-cyan-color {
  color: #7bdcb5; }

.has-vivid-green-cyan-color.has-vivid-green-cyan-color {
  color: #00d084; }

.has-pale-cyan-blue-color.has-pale-cyan-blue-color {
  color: #8ed1fc; }

.has-vivid-cyan-blue-color.has-vivid-cyan-blue-color {
  color: #0693e3; }

.has-very-light-gray-color.has-very-light-gray-color {
  color: #eee; }

.has-cyan-bluish-gray-color.has-cyan-bluish-gray-color {
  color: #abb8c3; }

.has-very-dark-gray-color.has-very-dark-gray-color {
  color: #313131; }


.wp-block-columns {
  display: flex;
  margin: 20px 0;
  align-content: space-between;
  flex-wrap: wrap;
}
  @media (min-width: 782px) {
    .wp-block-columns {
      flex-wrap: nowrap; } 
}

.wp-block-column {
  flex-grow: 1;
  margin-bottom: 1em;
  flex-basis: 100%;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word; }
  @media (min-width: 600px) {
    .wp-block-column {
      flex-basis: calc(50% - 16px);
      flex-grow: 0; }
      .wp-block-column:nth-child(even) {
        margin-left: 32px; } }
  @media (min-width: 782px) {
    .wp-block-column:not(:first-child) {
      margin-left: 32px; } }

      
      .wp-block-button {
        color: #fff;
        margin-bottom: 1.5em; }
        .wp-block-button.aligncenter {
          text-align: center; }
        .wp-block-button.alignright {
          /*rtl:ignore*/
          text-align: right; }
      
      .wp-block-button__link {
        background-color: #32373c;
        border: none;
        border-radius: 28px;
        box-shadow: none;
        color: inherit;
        cursor: pointer;
        display: inline-block;
        font-size: 18px;
        margin: 0;
        padding: 12px 24px;
        text-align: center;
        text-decoration: none;
        overflow-wrap: break-word; }
        .wp-block-button__link:hover, .wp-block-button__link:focus, .wp-block-button__link:active, .wp-block-button__link:visited {
          color: inherit; }
      
      .is-style-squared .wp-block-button__link {
        border-radius: 0; }
      
      .is-style-outline {
        color: #32373c; }
        .is-style-outline .wp-block-button__link {
          background-color: transparent;
          border: 2px solid currentcolor; }

       
            
            .wellness-btn {
              display: block;
              height: 100px;
              text-align: center;
              background: #32373c;
              line-height: 100px;
              font-weight: 700;
              color: white;
              &.mind{
                background-color: #e5e5f7;
                background: repeating-linear-gradient( 45deg, #575cb450, #575cb450 10px, #e5e5f7 10px, #e5e5f7 50px );
                color: #32373c;
              }
              &.body{
                background-color: #f6f7e5;
                background: repeating-linear-gradient( 45deg, #ebf74450, #ebf74450 10px, #f6f7e5 10px, #f6f7e5 50px );
                color: #32373c;
              }
              &.soul{
                background-color: #f7ece5;
                background: repeating-linear-gradient( 45deg, #f7774450, #f7774450 10px, #f6f7e5 10px, #f6f7e5 50px );
                color: #32373c;
              }
            }
          
            .wellness-image-btn {
              display: block;
              height: 140px;
              text-align: center;
              background-image: url(/images/AGP_wave_icon.png);
              line-height: 150px;
              font-weight: 700;
              color: black;
             
            }
          
                
            .wp-block-cover-image,
            .wp-block-cover {
              position: relative;
              background-color: #000;
              background-size: cover;
              background-position: center center;
              min-height: 430px;
              width: 100%;
              margin: 0 0 1.5em 0;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden; }
              .wp-block-cover-image.has-left-content,
              .wp-block-cover.has-left-content {
                justify-content: flex-start; }
                .wp-block-cover-image.has-left-content h2,
                .wp-block-cover-image.has-left-content .wp-block-cover-image-text,
                .wp-block-cover-image.has-left-content .wp-block-cover-text,
                .wp-block-cover.has-left-content h2,
                .wp-block-cover.has-left-content .wp-block-cover-image-text,
                .wp-block-cover.has-left-content .wp-block-cover-text {
                  margin-left: 0;
                  text-align: left; }
              .wp-block-cover-image.has-right-content,
              .wp-block-cover.has-right-content {
                justify-content: flex-end; }
                .wp-block-cover-image.has-right-content h2,
                .wp-block-cover-image.has-right-content .wp-block-cover-image-text,
                .wp-block-cover-image.has-right-content .wp-block-cover-text,
                .wp-block-cover.has-right-content h2,
                .wp-block-cover.has-right-content .wp-block-cover-image-text,
                .wp-block-cover.has-right-content .wp-block-cover-text {
                  margin-right: 0;
                  text-align: right; }
              .wp-block-cover-image h2,
              .wp-block-cover-image .wp-block-cover-image-text,
              .wp-block-cover-image .wp-block-cover-text,
              .wp-block-cover h2,
              .wp-block-cover .wp-block-cover-image-text,
              .wp-block-cover .wp-block-cover-text {
                color: #fff;
                font-size: 2em;
                line-height: 1.25;
                z-index: 1;
                margin-bottom: 0;
                max-width: 610px;
                padding: 14px;
                text-align: center; }
                .wp-block-cover-image h2 a,
                .wp-block-cover-image h2 a:hover,
                .wp-block-cover-image h2 a:focus,
                .wp-block-cover-image h2 a:active,
                .wp-block-cover-image .wp-block-cover-image-text a,
                .wp-block-cover-image .wp-block-cover-image-text a:hover,
                .wp-block-cover-image .wp-block-cover-image-text a:focus,
                .wp-block-cover-image .wp-block-cover-image-text a:active,
                .wp-block-cover-image .wp-block-cover-text a,
                .wp-block-cover-image .wp-block-cover-text a:hover,
                .wp-block-cover-image .wp-block-cover-text a:focus,
                .wp-block-cover-image .wp-block-cover-text a:active,
                .wp-block-cover h2 a,
                .wp-block-cover h2 a:hover,
                .wp-block-cover h2 a:focus,
                .wp-block-cover h2 a:active,
                .wp-block-cover .wp-block-cover-image-text a,
                .wp-block-cover .wp-block-cover-image-text a:hover,
                .wp-block-cover .wp-block-cover-image-text a:focus,
                .wp-block-cover .wp-block-cover-image-text a:active,
                .wp-block-cover .wp-block-cover-text a,
                .wp-block-cover .wp-block-cover-text a:hover,
                .wp-block-cover .wp-block-cover-text a:focus,
                .wp-block-cover .wp-block-cover-text a:active {
                  color: #fff; }
              .wp-block-cover-image.has-parallax,
              .wp-block-cover.has-parallax {
                background-attachment: fixed; }
                @supports (-webkit-overflow-scrolling: touch) {
                  .wp-block-cover-image.has-parallax,
                  .wp-block-cover.has-parallax {
                    background-attachment: scroll; } }
              .wp-block-cover-image.has-background-dim::before,
              .wp-block-cover.has-background-dim::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: inherit;
                opacity: 0.5;
                z-index: 1; }
              .wp-block-cover-image.has-background-dim.has-background-dim-10::before,
              .wp-block-cover.has-background-dim.has-background-dim-10::before {
                opacity: 0.1; }
              .wp-block-cover-image.has-background-dim.has-background-dim-20::before,
              .wp-block-cover.has-background-dim.has-background-dim-20::before {
                opacity: 0.2; }
              .wp-block-cover-image.has-background-dim.has-background-dim-30::before,
              .wp-block-cover.has-background-dim.has-background-dim-30::before {
                opacity: 0.3; }
              .wp-block-cover-image.has-background-dim.has-background-dim-40::before,
              .wp-block-cover.has-background-dim.has-background-dim-40::before {
                opacity: 0.4; }
              .wp-block-cover-image.has-background-dim.has-background-dim-50::before,
              .wp-block-cover.has-background-dim.has-background-dim-50::before {
                opacity: 0.5; }
              .wp-block-cover-image.has-background-dim.has-background-dim-60::before,
              .wp-block-cover.has-background-dim.has-background-dim-60::before {
                opacity: 0.6; }
              .wp-block-cover-image.has-background-dim.has-background-dim-70::before,
              .wp-block-cover.has-background-dim.has-background-dim-70::before {
                opacity: 0.7; }
              .wp-block-cover-image.has-background-dim.has-background-dim-80::before,
              .wp-block-cover.has-background-dim.has-background-dim-80::before {
                opacity: 0.8; }
              .wp-block-cover-image.has-background-dim.has-background-dim-90::before,
              .wp-block-cover.has-background-dim.has-background-dim-90::before {
                opacity: 0.9; }
              .wp-block-cover-image.has-background-dim.has-background-dim-100::before,
              .wp-block-cover.has-background-dim.has-background-dim-100::before {
                opacity: 1; }
              .wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
              .wp-block-cover.alignleft,
              .wp-block-cover.alignright {
                max-width: 305px;
                width: 100%; }
              .wp-block-cover-image::after,
              .wp-block-cover::after {
                display: block;
                content: "";
                font-size: 0;
                min-height: inherit; }
                @supports ((position: -webkit-sticky) or (position: sticky)) {
                  .wp-block-cover-image::after,
                  .wp-block-cover::after {
                    content: none; } }
              .wp-block-cover-image.aligncenter, .wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
              .wp-block-cover.aligncenter,
              .wp-block-cover.alignleft,
              .wp-block-cover.alignright {
                display: flex; 
                align-items: stretch;}
              .wp-block-cover-image .wp-block-cover__inner-container,
              .wp-block-cover .wp-block-cover__inner-container {
                width: calc(100% - 70px);
                z-index: 1;
                color: #f8f9f9; 
                p{
                  height: 100%;
                  a{
                    color: #f8f9f9;   
                  }
                }
              }
              .wp-block-cover-image p,
              .wp-block-cover-image h1,
              .wp-block-cover-image h2,
              .wp-block-cover-image h3,
              .wp-block-cover-image h4,
              .wp-block-cover-image h5,
              .wp-block-cover-image h6,
              .wp-block-cover-image .wp-block-subhead,
              .wp-block-cover p,
              .wp-block-cover h1,
              .wp-block-cover h2,
              .wp-block-cover h3,
              .wp-block-cover h4,
              .wp-block-cover h5,
              .wp-block-cover h6,
              .wp-block-cover .wp-block-subhead {
                color: inherit; }
          
 .hide-on-desktop{
   display: none;
 }
 
 .hide-on-mobile{
   display: block;
 }
 .has-small-font-size {
  font-size: 13px; }

.has-regular-font-size,
.has-normal-font-size {
  font-size: 16px; }

.has-medium-font-size {
  font-size: 20px; }

.has-large-font-size {
  font-size: 36px; }

.has-larger-font-size,
.has-huge-font-size {
  font-size: 42px; }
      
 @media only screen and (max-width: 420px) {
            
 .hide-on-desktop{
  display: block;
}

  .hide-on-mobile{
    display: none;
  }
}

