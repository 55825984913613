.podcastgallery {
  
  .banner { 
    margin-bottom: 16px; 
    
    .container { padding: 0 30px; }
  }

  h2 {
    font-size: 24px;
    line-height: 26px;
  }

  .podcast-gallery {
    list-style-type: none;
    width: 100%;
    padding: 0;

    li {
      padding: 18px 0;
      border-bottom: 2px solid #C4C4C4;

      &:last-child { border: none; }

      &:hover { opacity: 0.85; }
    }

    .text {

      h3, h4 { color:#001489; }
      
      h3, h4, h5 { margin-bottom: 4px; }

      h3 {
        font-family: 'BrandonGrotesque', sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 12px;
      }

      h4 {
        font-size: 20px;
        line-height: 22px;
        font-weight: bold;
      }

      h5 { 
        color: #282828;
        font-family: 'BrandonGrotesque', sans-serif;
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
      }

      .description {
        color: #282828;
        font-size: 16px;
        line-height: 18px;
      }
    }

    .players {
      padding-top: 24px;
      display: block;

      audio { 
        width: 100%;
        margin-bottom: 4px;
        outline: none;
      }

      .external {
        a {
          display: block;
          text-align: center;
          margin-bottom: 8px;

          img {
            width: auto;
            height: 45px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .podcastgallery {

    .banner { 
      margin-bottom: inherit; 
      
      .container { padding: 0 15px; }
    }

    h2 { 
      margin: 50px 0 24px 0;
      font-size: 36px;
      line-height: 38px;
    }

    #accordion h2 { margin: inherit; }

    .podcast-gallery {

      li { padding: 48px 0; }

      .text {
  
        h3 {
          font-size: 20px;
          line-height: 18px;
        }

        h4 {
          font-size: 28px;
          line-height: 30px;
        }

        h5 { 
          color: #282828;
          font-family: 'BrandonGrotesque', sans-serif;
          font-weight: bold;
          font-size: 20px;
          line-height: 18px;
          margin-bottom: 20px; 
        }

        .description { 
          font-size: 18px;
          line-height: 18px; 
        }
      }

      .players {
        padding-top: 24px;
        display: flex;
        justify-content: space-between; 
        align-items: center;

        audio { 
          max-width: 450px; 
          margin-bottom: inherit;
        }
      }
    }
  }
}  

@media screen and (min-width: 992px) { 
  .podcastgallery .podcast-gallery .players {
    .external a { 
      margin: 0 0 0 4px; 
      display: inline-block;
    }
  }
}