.videogallery  {
  
  .video-gallery-title {
    text-align: center;

    &.withdesc { margin-bottom: 0; }
  }

  .video-gallery-description { 
    text-align: center; 
    font-size: 24px;
  }

  .video-gallery {
    list-style-type: none;
    width: 100%;
    padding: 0;

    li {
      padding-bottom: 2rem;
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }

    .text {
      padding-top: 1rem;
    }    
  }
}

@media only screen and (min-width: 420px) {
  .videogallery {

    h2.video-gallery-description { font-size: 2.25rem; }
  }
}

@media screen and (min-width: 768px) {
  .videogallery {

    .video-gallery {
      display: flex;
      flex-wrap: wrap;

      li {
        width: (1/3) * 100%;
        padding: 1rem;
      }
    }
  }
}