.modules {
  .content-section p {
    margin-bottom: 0;
    padding-bottom: 30px;
  }

  .banner {
    padding: 70px 0;
  }
  .tutorial-pop-up {
    background: #222732;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    position: relative;
    margin-bottom: 15px;
    &.hide {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: solid 15px #222732;
      border-left: solid 25px transparent;
      border-right: solid 25px transparent;
    }
    .close-tutorial {
      color: white;
      right: 10px;
      top: 0px;
      position: absolute;
    }
  }
  .module-intro-area {
    padding: 30px 0px;
    .progress-area {
      width: 230px;
      height: 230px;
      padding-top: 15px;
      background: #22303c;
      border-radius: 360px;
      .progress-bar-container {
        margin: 0 auto;
        position: relative;
        width: 200px;
        height: 200px;
        .progress-bar-content {
          text-align: center;
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            height: 30px;
            width: auto;
          }
          h4 {
            padding: 0 10px;
            margin: 15px 0px;
            font-family: 'BrandonGrotesque', sans-serif;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
    }
    .module-intro-container {
      .module-intro-position {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        .module-intro {
          p {
            font-style: italic;
            font-size: 30px;
            line-height: 35px;
          }
        }
      }
    }
  }

  .body-diagram {
    margin: 0 auto;
    text-align: center;
    .diagram {
      position: relative;
      text-align: center;
      img {
        display: none;
        margin: 0 auto;
        &.active {
          display: block;
        }
      }
    }
    .items {
      float: left;
      margin: 75px 0 0 30px;
      width: 320px;
      .panel {
        &.active {
          div {
            display: block;
          }
        }
        div {
          display: none;
        }
      }
    }
  }

  #module {
    .full-width-tabs {
      margin: 15px 0px;
      .nav-fill {
        border-bottom: 0;
        .nav-link {
          border: 0px;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.5rem;
          color: #96b1c4;
          &.active {
            border: 0;
            background: none;
            color: #00d2b6;
            img {
              filter: invert(32%) sepia(14%) saturate(360%) hue-rotate(189deg) brightness(93%) contrast(92%);
            }
          }
          &:hover {
            border: 0;
          }
        }
      }
      img {
        margin: 0 !important;
      }
      .tab-pane {
        color: #393d46;
        margin-right: 15%;
        margin-left: 40px;
        padding-bottom: 50px;
        padding-top: 30px;
        border-bottom: 1px solid #393d46;
        &:before {
          content: '>';
          font-size: 10rem;
          position: absolute;
          left: -40px;
          top: 10%;
        }
        h2 {
          font-style: normal;
          font-family: 'BrandonGrotesque', Arial, sans-serif;
          &:after {
            background-color: #393d46;
          }
        }
      }
    }

    .hide {
      display: none;
    }
    .progress {
      height: 0.5rem;
      border-radius: 0;
    }
    #accordion {
      #headingOne {
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
      margin-top: -7px;
      h2 {
        font-family: 'BrandonGrotesque', sans-serif;
        font-size: 22px;
        font-style: normal;
      }
      .card {
        border: 0px;
      }
      .card-header {
        font-family: 'BrandonGrotesque', Arial, sans-serif;
        background: #ffd000;
        color: #222732;
        padding: 15px;
        border-radius: 0;
      }
      .card-body {
        background: #393d47;
        color: white;
        padding: 15px;
        p {
          font-size: 15px;
          strong {
            font-size: 18px;
          }
        }
      }
    }
    .slick-slide {
      img {
        margin: 30px;
        max-width: 100%;
        height: auto;
        &.aligncenter {
          margin: 0 auto !important;
        }
      }
    }
    .slick-prev {
      background: url(/icons/leftArrow.png);
      background-size: 100%;
      left: -45px;
      &:before {
        content: '';
      }
    }
    .slick-next {
      background: url(/icons/rightArrow.png);
      background-size: 100%;
      right: -45px;
      &:before {
        content: '';
      }
    }
    .last-slide {
      background: url(/icons/finish.png);
      background-size: 100%;
      right: -45px;
      &:before {
        content: '';
      }
    }
    .slick-prev,
    .slick-next {
      width: 90px;
      height: 90px;
      top: 180px;
      z-index: 999;
    }
    .section {
      color: #393d46;
      h2 {
        font-family: 'BrandonGrotesque', sans-serif;
        font-size: 22px;
        font-style: normal;
        position: relative;
        padding-bottom: 20px;
        &:after {
          height: 5px;
          width: 75px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          background-color: #393d46;
          content: '';
        }
      }
      .container {
        padding: 60px 10%;
        overflow: hidden;
        .card {
          background: none;
          .card-header {
            background: #4d515a;
            border-radius: 0;
            border: 0;
            h5,
            a {
              color: white;
              margin: 0;
            }
          }
          .card-block {
            background: #393d47;
            color: white;
            padding: 0.75rem 1.25rem;
          }
        }
        table {
          width: 100%;
          td {
            border: 1px solid #393d46;
            padding: 10px;
            vertical-align: top;
          }
        }
      }
    }
    .question {
      color: #ffffff;
      h2 {
        font-family: 'BrandonGrotesque', sans-serif;
        font-size: 22px;
        font-style: normal;
        position: relative;
        padding-bottom: 0px;
        font-weight: bold;
        margin-bottom: 0;
      }
      h3 {
        font-family: 'BrandonGrotesque', sans-serif;
        font-size: 18px;
        font-weight: bold;
      }
      .container {
        padding: 60px 10%;
      }
    }
    .example {
      color: #fff;
      background: #222731;
      position: relative;
      .full-width-tabs {
        .tab-pane {
          color: #fff;
          border-bottom: 1px solid #fff;
          h2 {
            &:after {
              background-color: #fff;
            }
          }
        }
      }
      .btn {
        right: 0;
        position: absolute;
        bottom: 0;
        margin-bottom: 0;
        &.finish {
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 80px;
        }
      }
      .prev {
        left: 0;
        right: auto;
      }
      .container {
        padding: 0;
        &.question-container {
          padding: 60px 10%;
        }
        .example-header {
          background: #4d5159;
          padding: 15px 10%;
          h2 {
            font-family: 'BrandonGrotesque', sans-serif;
            font-size: 22px;
            font-style: normal;
            margin: 0px;
          }
        }
        .example-content {
          background: #393d46;
          padding: 30px 10%;
          overflow: hidden;
          table {
            width: 100%;
            td {
              border: 1px solid white;
              padding: 10px;
              vertical-align: top;
            }
          }
          .card {
            background: none;
            .card-header {
              background: #222731;
              border-radius: 0;
              border: 0;
              h5,
              a {
                font-family: 'BrandonGrotesque', Arial, sans-serif;
                color: #fff;
                margin: 0;
              }
            }
            .card-block {
              background: #4e515b;
              color: white;
              padding: 0.75rem 1.25rem;
            }
          }
        }
        .question-header {
          background: #fff;
          padding: 15px 30px;
          h2 {
            font-family: 'BrandonGrotesque', sans-serif;
            font-size: 22px;
            font-style: normal;
            color: black;
            margin: 0px;
            font-weight: bold;
          }
        }
        .question-content {
          background: #393d46;
          ul {
            list-style: none;
            padding: 0;
            &.complete {
              li:hover {
                background: #393d46;
              }
            }
            li {
              background: #393d46;
              padding: 30px 75px 30px 30px;
              position: relative;
              .more-answer-details {
                margin-top: 15px;
              }
              &:hover {
                background: #43474f;
              }
              &.correct {
                border-left: 10px solid #00e155;
                background: #43474f;
                &:after {
                  background: url(/icons/tickBox.png);
                  background-size: cover !important;
                  background-position: right;
                  border: 0px;
                  width: 30px;
                  height: 30px;
                  top: 50%;
                  transform: translateY(-50%);
                }
                &:hover {
                  background: #43474f;
                }
              }
              &.incorrect {
                border-left: 10px solid #ff0000;
                background: #43474f;
                &:after {
                  background: url(/icons/crossBox.png);
                  background-size: cover !important;
                  background-position: right;
                  border: 0px;
                  width: 30px;
                  height: 30px;
                  top: 50%;
                  transform: translateY(-50%);
                }
                &:hover {
                  background: #43474f;
                }
              }
              &:after {
                display: block;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                position: absolute;
                content: '';
                right: 30px;
                transform: translateY(-20px);
                border: 3px solid white;
                background: url(/icons/tickBox.png);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .modules {
    .module-intro-area {
      .progress-area {
        margin: 0 auto;
      }
      .module-intro-container {
        .module-intro-position {
          position: relative;
          margin-top: 30px;
        }
      }
    }
    #module {
      .slick-slide {
        img {
          max-width: 100%;
          height: auto;
          &.alignright {
            float: none;
          }
        }
      }
      .slick-next,
      .slick-prev {
        display: none;
      }
      .section {
        .container {
          padding: 60px 15px;
        }
      }
      .example {
        .container {
          padding: 60px 15px;
          .example-header {
            padding: 15px;
          }
          .example-content {
            padding: 30px 15px;
          }
          .question-header {
            h2 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modules {
    .module-intro-area {
      .progress-area {
        width: 170px;
        height: 170px;
        padding-top: 10px;
        .progress-bar-container {
          width: 150px;
          height: 150px;
          .progress-bar-content {
            img {
              height: 20px;
            }
            h4 {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
