/**
 * variables for supersteady
 */

 $super-blue-light: #5bc2e7;
 $super-blue-mid: #009cde;
 $super-blue-dark: #004f71;
 $super-white: #fff;
 $super-font: Georgia,Times,'Times New Roman',serif;

.super-steady{
    h1, h2, h3, h4{
        color: $tertiary;
        line-height: 4.8rem;
        font-style: normal;
        font-family: $font-family-base
    }
    h1, h4{
        &:after {
            content: "";
            height: 10px;
            width: 90px;
            position: absolute;
            background-color: $tertiary;
        }
    }
    h1{
        font-weight: 900;
        font-size: 4rem;
        span{
            font-family: $super-font;
            font-style: italic;
            font-size: 1.5rem;
            text-transform: none;
            font-weight: 400;
            display: block;
        }
    }

    h2 {
        font-size: 3.3rem;
        font-weight: 700;
        margin-top: 4rem;
    }

    h3, h4 {
        font-size: 2.65rem;
        font-weight: 700;
        margin-top: 2rem;
        margin-bottom: 1rem;
        line-height: 3.9rem;
    }

    p{
        font-size: 2rem;
        line-height: 2.9rem;
        &.bran-bold {
            font-weight: 700;
        }
    }

    .character-bar {
        background: -moz-linear-gradient(180deg,$super-white 0,$super-white 13.5%,$super-blue-light 13.5%,$super-blue-light 79.7%,$super-white 79.9%);
        background: -webkit-linear-gradient(180deg,$super-white 0,$super-white 13.5%,$super-blue-light 13.5%,$super-blue-light 79.7%,$super-white 79.9%);
        background: linear-gradient(180deg,$super-white 0,$super-white 13.5%,$super-blue-light 13.5%,$super-blue-light 79.7%,$super-white 79.9%);
        margin: 15px -15px;
    }
    .gutters {
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
    }

    .img-responsive {
        max-width: 100%;
        height: auto;
    }

    .bg-yellow {
        background-color: $primary;
        margin: 0 -15px;
    }

    .bg-blue-light, .bg-blue-mid, .bg-blue-dark {
        background-color: $super-blue-light;
        color: $super-white;
        padding: 0 3rem;
        h4{
            color: $super-white;
            position: relative;
            margin: 35px 0;
            &:after{
                background-color: $super-white;
                left: 0;
                bottom: -20px;
            }
        }
    }

    .bg-blue-mid{
        background-color: $super-blue-mid;
    }

    .bg-blue-dark {
        background-color:  $super-blue-dark;
        margin: 0 -15px;
        padding: 0 2rem;
    }

    .orange-btn {
        display: inline-block;
        background-color: $tertiary;
        font-size: 1.7rem;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 700;
        letter-spacing: .5px;
        padding: 8px 30px;
        color: $super-white;
        text-decoration: none;
        transition: all .5s;
        margin-bottom: 20px;
    }

    .center-small-screen {
        margin-left: 0;
        margin-right: 0;
        text-align: center;
    }
    .icon-print{
        display: inline-block;
        height: 100%;
        vertical-align: sub;
    }
}

@media screen and (min-width: 768px) {
    .super-steady{
        h1{
            font-size: 3.4rem;
            span{
                font-size: 2.3rem;
            }
        }
        h2 {
            margin-top: 2rem;
            margin-bottom: 0.7rem;
            font-size: 2.9rem;
        }
        h3, h4 {
            font-size: 2.3rem;
        }
        p{
            font-size: 1.7rem;
        }
        .orange-btn {
            font-size: 1.4rem;
        }
        .gutters {
            padding-right: 15px;
            padding-left: 15px;
        }
        .container-fluid{
            &.gutters {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .bg-yellow{
            h2 {
                line-height: 4rem;
            }
        }
        .character-bar {
            background: -moz-linear-gradient(180deg,$super-white 0,$super-white 13.5%,$super-blue-light 13.5%,$super-blue-light 79.6%,$super-white 80%);
            background: -webkit-linear-gradient(180deg,$super-white 0,$super-white 13.5%,$super-blue-light 13.5%,$super-blue-light 79.6%,$super-white 80%);
            background: linear-gradient(180deg,$super-white 0,$super-white 13.5%,$super-blue-light 13.5%,$super-blue-light 79.6%,$super-white 80%);
        }
    }
}

@media screen and (min-width: 992px) {
    .super-steady{
        h1, h2, h3, h4{
            line-height: 3.8rem;
        }
        h1{
            font-size: 3rem;
            span{
                font-size: 2rem;
            }
        }
        h2 {
            font-size: 2.5rem;
        }
        h3, h4 {
            font-size: 2rem;
        }
        p{
            font-size: 1.5rem;
            line-height: 2.3rem;
        }
        .orange-btn {
            font-size: 1.3rem;
        }
        .bg-blue-light, .bg-blue-mid, .bg-blue-dark {
            padding: 0 0 0 2rem;
            h4{
                margin-top: 2.8rem;
                line-height: 2.6rem;
            }
        }
        .bg-blue-dark{
            position: relative;
            padding: 0;
        }
        .stick-bot {
            position: absolute;
            bottom: 1px;
            right: 0;
            margin-bottom: 0;
        }
        .fluid-left {
            float: right;
            max-width: 560px;
        }
        .fluid-right {
            float: left;
            max-width: 570px;
            padding: 0 0 0 15px;
        }
        .center-small-screen {
            padding-right: 4rem;
        }
    }
}