.wb-mind-color{
  color: #F47B20;
  a{
    color: #F47B20;
    :hover {
      color: #F47B20;
       text-decoration: underline;
    }
  }
}
.wb-body-color{
  color: #009CDE;
  a{
    color: #009CDE;
    :hover {
      color: #009CDE;
       text-decoration: underline;
    }
  }
}
.wb-soul-color{
  color: #AA0061;
  a{
    color: #AA0061;
    :hover {
      color: #AA0061;
       text-decoration: underline;
    }
  }
}
.wb-landing-cover-block{
  color: white;
  padding: 12px;
  padding-bottom: 24px;
  align-items: inherit;
  &.black, &.black *, &.black p a{
    color: black;
  }
  p{
    img{
      margin-left: auto;
      max-width: 25%;
    }
    a{
      text-decoration: underline;
    }
  }
  h2{
    padding-left: 0;
    font-weight: 800;
    font-style: normal;
  }
  .wp-block-cover__inner-container{
    display: flex;
    flex-direction: column;
    p:last-child{
      display: flex;
      align-items: flex-end;
    }
  }
}

.wb-full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.wb-youtube-100{
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe{    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.wb-middle{
  .wp-block-column{
    align-self: center;
  }
}
.wb-button{
  a{    
    min-width: 349px;
    height: 140px;
    align-items: center;
    display: grid;
  }
}

.wb-button-body{
  a{
    background-image: url(/images/wb-btn-body.png);
  }
}
.wb-button-soul{
  a{
    background-image: url(/images/wb-btn-soul.png);
  }
}
.wb-button-mind{
  a{
    background-image: url(/images/wb-btn-mind.png);
  }
}

.wb-h2{
  font-style: normal;
  font-weight: 600;
}

.wp-block-media-text {
  display: grid; }

.wp-block-media-text {
  grid-template-rows: auto;
  align-items: center;
  grid-template-areas: "media-text-media media-text-content";
  grid-template-columns: 50% auto; }
  .wp-block-media-text.has-media-on-the-right {
    grid-template-areas: "media-text-content media-text-media";
    grid-template-columns: auto 50%; }

.wp-block-media-text .wp-block-media-text__media {
  grid-area: media-text-media;
  margin: 0; }

.wp-block-media-text .wp-block-media-text__content {
  word-break: break-word;
  grid-area: media-text-content;
  padding: 0 8% 0 8%; }

.wp-block-media-text > figure > img,
.wp-block-media-text > figure > video {
  max-width: unset;
  width: 100%;
  vertical-align: middle; }
 
 .wp-block-image-render img{
   image-rendering: -moz-crisp-edges;
   image-rendering: -o-crisp-edges;
   image-rendering: -webkit-optimize-contrast;
   image-rendering: crisp-edges;
 }
 
@media only screen and (max-width: 420px) {
            
  .wb-button{
    float: none;
    width: 100%;
    a{
      min-width: inherit;
      width: 100%;
    }
  }
  .wb-youtube-100{
    position: relative;
    padding-bottom: inherit;
    height: inherit;

  }
  .wb-middle{
    .wp-block-column{
      align-self: center;
      margin-bottom: 0;
    }
  }
  .wb-landing-cover-block{
    align-items: center;
  }
  
 }