@font-face {
  font-family: 'BrandonGrotesque';
  src: url('/fonts/brandon_blk-webfont.woff2') format('woff2'), url('/fonts/brandon_blk-webfont.woff') format('woff');
  font-display: swap;
  font-weight: 900;
}
@font-face {
  font-family: 'BrandonGrotesque';
  src: url('/fonts/brandon_bld-webfont.woff2') format('woff2'), url('/fonts/brandon_bld-webfont.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: 'BrandonGrotesque';
  src: url('/fonts/brandon_reg-webfont.woff2') format('woff2'), url('/fonts/brandon_reg-webfont.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'MercuryTextG1';
  font-display: swap;
  src: url('/fonts/MercuryTextG1-BoldItalic.woff2') format('woff2'), url('/fonts/MercuryTextG1-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'MercuryTextG1';
  font-display: swap;
  src: url('/fonts/MercuryTextG1-Italic.woff2') format('woff2'), url('/fonts/MercuryTextG1-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'MercuryTextG1';
  font-display: swap;
  src: url('/fonts/MercuryTextG1-Roman.woff2') format('woff2'), url('/fonts/MercuryTextG1-Roman.woff') format('woff');
  font-weight: 400;
}

// Montserrat-Bold
// Montserrat-Light
// MyriadPro-Regular

@import '_variables.scss';
// @import "_mixins.scss";

@import '~bootstrap/scss/bootstrap.scss';

// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";

html {
  font-size: 12px;
  @include media-breakpoint-up(sm) {
    font-size: 13px;
  }
  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 15px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
}

p {
  &:last-child {
    margin-bottom: 0px;
  }
}

a {
  color: #cf4520;
}

main {
  display: block;
  width: 100%;
}

.lightbox div {
  position: fixed !important;
  z-index: 10;

  > * {
    &:first-child {
      font-size: 2rem !important;
    }
    &:nth-child(2) {
      font-size: 5rem !important;
    }
    &:nth-child(3),
    &:nth-child(4) {
      font-size: 6rem !important;
    }
  }

  div {
    padding: 0 15px 15px 15px !important;
    z-index: 1;
    width: 100vw;
    height: 100vh;
  }

  svg {
    z-index: 2;
  }

  path {
    stroke: #000;
    stroke-width: 0.5px;
  }

  iframe {
    z-index: 3;
  }
}

@import '_general.scss';
@import '_pages.scss';
@import '_posts.scss';
@import '_modules.scss';
@import '_forms.scss';
@import '_superSteady.scss';
@import '_podcastGallery.scss';
@import '_videoGallery.scss';
@import '_ctaBlock.scss';
@import '_errorPages.scss';
@import '_disclaimers.scss';
@import '_notifications.scss';
@import '_custom.scss';
@import '_wpBlock.scss';
@import '_wbCustom.scss';
@import '_hcpRegister.scss';