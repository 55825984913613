.ctablock-container {

  .ctablock {
    background-size: cover;

    h2,
    .content{
      color: #ffffff;
      font-family: BrandonGrotesque;
      margin-bottom: 20px;
      font-size: 2.5rem
    }

    h2 {
      position: relative;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;

      &:after {
        height: 2.5px;
        width: 40px;
        position: absolute;
        bottom: -6px;
        left: 1px;
        background-color: #fff;
        content: "";
      }
    }

    .content {
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #ffffff;
    }

    .buttons {
      .btn {
        margin: 0 10px 10px 0;
        text-transform: uppercase;
      }
    }
  }
}