.home {
  color: #ffffff;
  a:not(.btn) {
    color: #ffffff;
  }
  .banner,
  .education,
  .modules,
  .reviews,
  .caseStudies,
  .ordering,
  .adBanner {
    min-height: 500px;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .banner {
    min-height: 400px;
  }
  .adBanner {
    // offsets the margin bottom on the home banner (necessary otherwise is a white gap between home and ad bannerss)
    margin-top: -30px;
  }
  .modules {
    color: #e4572d;
    h1 {
      &:after {
        background-color: #e4572d;
      }
    }
    .background-image-contain {
      position: relative;
      bottom: -50px;
    }
    .slick-slide {
      img {
        max-width: 100%;
      }
    }
    .slick-dots li button:before {
      font-size: 10px;
    }
    .slick-dots li:hover button:before,
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: #e4572d;
    }
  }
  .full-width-link {
    padding: 24px;
    font-size: 36px;
    font-weight: 900;
    background-color: #e4572d;
    color: #ffffff;
    a {
      &:after {
        content: '\203A';
        padding-left: 10px;
      }
    }
  }
  .reviews,
  .caseStudies,
  .ordering {
    display: flex;
    align-items: flex-end;
    background-position: top center;
    h2 {
      @include underline();
      padding-bottom: 20px;
      margin-bottom: 26px;
      a {
        &:after {
          content: '\203A';
          padding-left: 10px;
        }
      }
    }
  }
  .reviews {
    background-position: center 50px;
    background-size: 75%;
    background-color: #57badf;
  }
  .caseStudies,
  .ordering {
    background-position: top center;
    background-color: #004f71;
  }
}

.content-page {
  img {
    display: block;
    height: auto;
    max-width: 100%;
  }
}

#root {
  footer {
    margin-top: 0 !important;
  }
}

.caseStudies,
.ordering {
  .outer-box {
    background-color: #f4f4f4;
    padding: 1rem;
  }
  h2 {
    color: $tertiary;
    font-style: normal;
    font-weight: 700;
  }
  .heading {
    color: $tertiary;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
  }
  .table {
    th,
    td {
      border: none;
    }
    .add {
      text-align: center;
    }
    .image {
      width: 15%;
      img {
        max-width: 150px;
        width: 100%;
      }
    }
  }
  .code {
    width: 15%;
    font-weight: 700;
  }
  .item {
    width: 55%;
  }
  .add {
    width: 15%;
    img {
      cursor: pointer;
    }
  }
  .cart-item {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $tertiary;
    .icon-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .icon-block {
        padding: 5px;
        background-color: #ffffff;
      }
    }
  }
}

.reviews {
  .intro {
    margin-top: 2rem;
    margin-bottom: 2rem;

    h2 {
      font-size: 36px;
      font-style: italic;
    }
  }
  .helpline {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 30px;
    background-color: #f4f4f4;

    a,
    h2 {
      color: #e4572d;
    }
  }
  .review-form {
    margin-top: 2rem;
    margin-bottom: 2rem;
    img {
      width: 100%;
    }
    .form-group {
      img {
        width: 20%;
      }
    }
  }
  form {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 2rem;
    background-color: #ffffff;
  }
  .references {
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: #ffffff;
  }
}

.login {
  .large-font {
    font-size: 24px;
  }
}

.register {
  .privacy {
    padding-top: 1em;
  }

  .circle {
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 5px solid #005294;
    color: #ffffff;
    margin: 50px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;

    @include media-breakpoint-up(lg) {
      display: inline-flex;
    }

    > div {
      pointer-events: none;
    }

    &:hover {
      background-color: #64ccc9;
      border: 5px solid #64ccc9;
    }
  }
  .thanks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin-bottom: 60px;
    }
    h3 {
      margin-bottom: 40px;
      font-weight: 700;
      text-transform: uppercase;
    }
    p {
      text-align: center;
    }
  }
}

.academy {
  .banner {
    padding: 70px 0;
  }
}

.page {
  .intro {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .outer-block {
    padding: 1rem;
    margin-bottom: 1rem;
    color: #ffffff;
    @include media-breakpoint-up(md) {
      padding: 125px 100px;
    }
    .inner-block {
      padding: 1rem;
      background-color: #ffffff;
      color: #000000;
      @include media-breakpoint-up(md) {
        padding: 50px;
      }
    }
  }
  &.single {
    margin-bottom: 30px;
  }

  &.academy-margin {
    margin-top: -90px;
  }
  .module-status {
    width: auto;
    background: #222731;
    display: inline-block;
    color: #ffd100;
    padding: 5px 15px;
    border-radius: 5px;
    margin-bottom: 50px;
    margin-top: 100px;
    font-style: italic;
  }
  .progress-area {
    width: 150px;
    height: 150px;
    padding-top: 15px;
    background: #22303c;
    border-radius: 360px;
    margin-bottom: 30px;
    &.total {
      background: #ffd000;
    }
    &:hover {
      cursor: pointer;
    }
    .progress-bar-container {
      margin: 0 auto;
      position: relative;
      width: 120px;
      height: 120px;
      font-size: 12px;
      .progress-bar-content {
        font-weight: bold;
        line-height: 25px;
        text-align: center;
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          height: 15px;
        }
        img.agp {
          height: 35px;
          margin-bottom: 5px;
        }
        h4 {
          font-size: 12px;
          padding: 0 10px;
          margin: 5px 0px;
          font-family: 'BrandonGrotesque', sans-serif;
          font-weight: bold;
        }
        .percentage {
          font-size: 40px;
        }
      }
    }
  }
  #accordion {
    margin-top: 30px;
    h2 {
      font-family: 'BrandonGrotesque', sans-serif;
      font-size: 22px;
      font-style: normal;
    }
    .card {
      border: 0px;
    }
    .card-header {
      background: #ffd000;
      color: #222732;
      padding: 15px;
      border-radius: 0;
    }
    .card-body {
      background: #393d47;
      color: white;
      padding: 15px;
      p {
        font-size: 15px;
        strong {
          font-size: 18px;
        }
      }
    }
  }
}

.content-page {
  .body-diagram {
    margin: 0 auto;
    text-align: center;
    .diagram {
      width: 90%;
      float: left;
      position: relative;
      text-align: center;
      img {
        display: none;
        margin: 0 auto;
        &.active {
          display: block;
        }
      }
    }
    .items {
      float: left;
      margin: 75px 0 0 30px;
      width: 320px;
      .panel {
        &.active {
          div {
            display: block;
          }
        }
        div {
          display: none;
        }
      }
    }
  }
  .full-width-tabs {
    padding: 60px 0;
    margin-bottom: 30px;
    background: rgb(47, 87, 122);
    background: linear-gradient(90deg, rgba(47, 87, 122, 1) 0%, rgba(53, 95, 133, 1) 35%, rgba(49, 130, 163, 1) 100%);
    .nav-fill {
      border-bottom: 0;
      .nav-link {
        border: 0px;
        &.active {
          border: 0;
          background: none;
          img {
            filter: invert(68%) sepia(76%) saturate(675%) hue-rotate(129deg) brightness(138%) contrast(60%);
          }
        }
        &:hover {
          border: 0;
        }
      }
    }
    .tab-pane {
      color: white;
      margin-right: 15%;
      margin-left: 40px;
      padding-bottom: 50px;
      padding-top: 30px;
      border-bottom: 1px solid white;
      &:before {
        content: '>';
        font-size: 10rem;
        position: absolute;
        left: -40px;
        top: 10%;
      }
      h2 {
        font-style: normal;
        font-family: 'BrandonGrotesque', Arial, sans-serif;
      }
    }
  }
}

/* Case study listing */
.listing-content {
  .item-row {
    margin-bottom: 4rem;
    &:nth-of-type(even) {
      //background-color: #e4e4e4;
    }
    h3 {
      font-family: $font-family-base;
      font-size: 1.5rem;
      font-weight: bold;
      text-decoration: underline;
      a.nav-link {
        color: $tertiary;
        padding: 0.5rem 0;
      }
    }
  }
  .stats-content {
    margin-top: 0.8em;
  }
}

/* Case study detail page table styling */
.casestudies {
  .post-wrapper {
    .page-content {
      td {
        border: 1px solid;
        padding: 0.5em;
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .thumbnail {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  .thumbnail-wrap {
    text-align: end;
  }
}

@media screen and (max-width: 992px) {
  .home {
    .banner,
    .education,
    .modules,
    .reviews,
    .caseStudies,
    .ordering {
      min-height: 400px;
    }
    .full-width-link {
      font-size: 25px;
    }
  }
  .page {
    &.academy-margin {
      margin-top: -50px;
    }
    .progress-area {
      margin: 0 auto 15px auto;
    }
  }
  .listing-content .filter-content {
    display: block;
    .boxed-radio-row {
      float: none;
    }
  }
}

/** AGP page */
.agp-wrapper {
  padding-bottom: 0rem !important;
  table {
    td {
      border: 1px solid;
      padding: 0.5em;
    }
  }
  .react-tabs {
    margin: 2em 0;
    max-width: 100%;
    .react-tabs__tab-list {
      display: flex;
      justify-content: space-around;
      border-bottom: initial;
      background-color: #ea541d;
      margin-bottom: 0;
    }
    .react-tabs__tab {
      border: initial;
      bottom: auto;
      border-radius: 0;
      padding: 1em;
      color: #fff;
    }
    .react-tabs__tab--selected {
      background-color: #f08861;
      border: initial;
    }
    .react-tabs__tab-panel {
      padding: 2em $apg-padding-lr;
      background-color: #f4f4f4;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  #module {
    img {
      background-color: #fff;
    }
    color: #fff;
    padding: $apg-padding-lr;
    padding-bottom: 0;
    background-color: #222731;
    .progress-bar {
      background-color: #ea541d;
    }
    .question-bottom-padding {
      height: 40px;
    }
    .slides {
      .slick-track {
        .slick-slide {
          padding: 1em 0;
        }
      }
    }
    .questions h3,
    .bottom-line {
      padding: 1em 0;
      &:after {
        content: '';
        display: block;
        width: 4em;
        padding: 0.2em 0;
        border-bottom: 5px solid;
      }
    }
    .questions {
      .container.question-container {
        .question-image img {
          margin: 1em 0 0 0;
        }
      }
      .questions-actions {
        margin-top: 2em;
        display: flex;
        justify-content: center;
        button.btn-restart {
          position: static;
        }
      }
    }
    .slick-slider {
      .slick-prev {
        left: -125px;
      }
      .slick-next {
        right: -125px;
      }
    }
    .slide-bottom-actions.example {
      text-align: center;
      height: 3rem;
      .prev {
        left: -5rem;
      }
      .next {
        right: -5rem;
      }
    }
  }
  #accordion {
    margin-top: 0px;
    padding-top: 0px;
  }
}

/* make tables scroll on small screens */
.contain-table {
  table {
    display: block;
    overflow-x: auto;
  }
}

.videoTracking-wrapper {
  .video {
    padding: 40px 0 0 0;
    line-height: 0;
  }
  .brochure {
    padding-top: 8px;

    .intro {
      padding-bottom: 20px;
    }
  }
  .youtubeVideos {
    margin-top: 20px;
    margin-bottom: 20px;
    a {
      overflow-wrap: break-word;
    }
  }
  .yellow-banner {
    background: #ffd144;
    padding: 15px;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-left: 0;
    h5 {
      margin-right: 10px;
      margin-bottom: 0;
    }
    .orange-btn {
      margin: 0;
    }
    .btn-orange {
      margin-bottom: 0;
    }
  }
  .white-banner {
    padding: 15px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
    h5 {
      margin-right: 10px;
      margin-bottom: 0;
    }
    .orange-btn {
      margin: 0;
    }
    .btn-orange {
      margin-bottom: 0;
    }
  }
  .image-banner {
    margin-right: 0;
    margin-left: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .disclaimers {
    &#accordion {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 375px) {
  .videoTracking-wrapper {
    .yellow-banner {
      .btn-orange {
        margin-top: 10px;
      }
    }
    .white-banner {
      margin-top: 0;
      .btn-orange {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .CircularProgressbar {
    display: none;
  }
  .progress-bar-content > div {
    max-width: 160px;
  }
  .nav-fill {
    border-bottom: 0;
    .nav-item {
      max-width: 20%;
      display: inline-block;
      flex: 1 1;
    }
  }
}

.meeting-box,
.casestudy-box {
  width: 100%;
  .title {
    text-align: center;
  }
  .image-gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    button::before {
      color: #cf4520;
      text-shadow: 0 2px 2px #cf4520;
    }
    .image-gallery-content {
      // Full Screen
      &.fullscreen {
        background: #fff;
        .image-gallery-slides {
          .image-gallery-slide {
            background: #fff;
          }
        }
      }
      .image-gallery-slide-wrapper {
        .image-gallery-slides {
          text-align: center;
          img {
            width: auto;
            max-width: 100%;
          }
          .image-gallery-description {
            font-size: 1.5em;
          }
        }
      }
      .image-gallery-thumbnails-wrapper {
      }
    }
  }
}
.casestudy-box {
  .image-gallery-image {
    width: 85%;
    margin: auto;
  }
  .title {
    border-bottom: 2px solid black;
    padding-bottom: 10px;
  }
}
.meetings {
  .listing-content {
    .item-row {
      margin-bottom: 2rem;
      .row-content {
        img {
          width: 150px;
          float: left;
          padding-right: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .meeting-box {
    .image-gallery {
      .image-gallery-content {
        .image-gallery-slide-wrapper {
          .image-gallery-slides {
            text-align: center;
            .image-gallery-image {
              img {
                width: 100%;
              }
            }
            .image-gallery-description {
              font-size: 1.5em;
            }
          }
        }
      }
    }
  }
  .meetings {
    .listing-content {
      .item-row {
        .row-content {
          img {
            float: none;
            width: 100%;
          }
          .summary {
            margin-top: 1em;
          }
        }
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  .nav-fill {
    .nav-item {
      flex: 1 1 0;
    }
  }
}
