/* For my account page - its using informed */
#my-account-form-container {
  .invalid-feedback {
    display: block;
  }
}

// To bypass bootstrap client-side validation
// when trying to bring granular feedback (temporary)
form {
  .form-control:valid.is-invalid {
    border-color: #dc3545 !important;
  }
  .form-control::placeholder {
    opacity: 0.5;
  }
}
