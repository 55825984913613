.hcp-register {
  .has-background {
    padding: 1rem;
  }
  img {
    width: 100%;
  }

  .thanks {
    text-align: center;
    display: block;

    img {
      width: auto;
      padding-bottom: 20px;
    }
  }

  .blue-freestyle {
    color: #001489;
  }
  .media-full {
    &.wp-block-media-text .wp-block-media-text__content {
      padding: 0;
    }
    @media (max-width: 768px) {
      &.wp-block-media-text {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  @media (max-width: 768px) {
    .wp-block-column {
      margin-bottom: 4rem;
    }
  }
}
.hcp-register-form {
  h1 {
    font-weight: initial;
    margin-bottom: 0;
    padding-bottom: inherit;
    &::after {
      display: none;
    }
  }
  h2 {
    font-style: normal;
    margin-bottom: inherit;
  }
  h3 {
    margin-bottom: 24px;
  }
  @media (min-width: 768px) {
    .outer-block {
      padding: 75px 100px;
    }
    h1 {
      font-size: 4rem;
    }
  }
}
