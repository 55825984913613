/**
 * Boostrap Variables / Mixins
 */

$primary: #FFD000;
$secondary: #222731;
$tertiary: #CF4520;

$font-family-base: 'BrandonGrotesque', Arial, sans-serif;

$headings-font-family: 'MercuryTextG1', 'Times New Roman', serif;

$btn-border-radius: 0px;

$input-color: #FFFFFF;
$input-placeholder-color: #FFFFFF;
$input-bg: #43474F;
$input-border-radius: 5px;

// $component-active-color: #43474F;
$component-active-bg: #43474F;

// AGP color
$apg-padding-lr: 5em;

@mixin underline() {
  position: relative;

  &:after {
    height: 5px;
    width: 75px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #FFFFFF;
    content: '';
  }
}

// Blue                                   rgba(0, 20, 137, 1)     #001489
// Yellow                                 rgba(255, 209, 0, 1)    #FFD100
// Orange                                 rgba(207, 69, 32, 1)    #CF4520
// Papillon Purple                        rgba(160, 94, 181, 1)   #A05EB5
// InsuLinx (US)
// Navigator
// Precision Blue
// Optium Blue
// Precision/Optium Ketone
// Precision H/Optium H
// Precision Pro
// Precision Neo H / Optium Neo H
// Precision / Optium Keytone (Hospital)
