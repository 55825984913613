$dim: 104px;
$dim-md: 110px;

.error-page{
  .octagon, .octagon:before  {
    width: $dim; height: $dim;
    position: relative;

  }

  .octagon {
    overflow: hidden;
    margin: 1rem auto 3rem auto;
    &:before {
      content: '';
      display: block;
      transform: rotate(45deg);
      background: $tertiary;
    }
    span{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 34px;
      color: #ffffff;
      font-weight: bold;
    }
  }
  h1, h2, h3{
    text-align: center;
  }
  h1{
    color: $tertiary;
    margin-bottom: 1rem;
    padding:0;
    &:after{
      display: none;
    }
  }
  h2{
    color: $secondary;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 576px) {
  .error-page{
    .octagon, .octagon:before  {
      width: $dim-md; height: $dim-md;
    }
    .octagon {
      margin: 3rem 1rem;
      float: right;
      span{
        font-size: 48px;
      }
    }
    h1, h2, h3{
      text-align: left;
    }
  }
}
