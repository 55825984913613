/**
 * General
 *  1. Header
 *  2. Footer
 *  3. Spinner
 *  4. Other
 */

/**
 * Header
 */

.header-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

#mainMenu {
  .btn-orange {
    margin-bottom: 0px;
  }
}

.modal.add-to-home-screen {
  display: block;
  background: white;
  top: auto;
  bottom: 0px;
  height: 120px;
  width: 100%;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.7);
  padding: 20px;
  z-index: 9999994;
  span {
    background-size: cover;
    display: inline-block;

    &.share {
      width: 11px;
      height: 15px;
      background-image: url('./share.svg');
    }

    &.menu {
      width: 10px;
      height: 10px;
      background-image: url('./menu.svg');
    }
  }

  img {
    position: absolute;
    width: 20px;
    right: 20px;
    top: 20px;
  }
}

.header-bottom {
  // height: 43px;
  width: 100%;
  background-color: $primary !important;
  .nav-link {
    color: $secondary !important;
    &:hover {
      cursor: pointer;
    }
  }
  .nav-item {
    .dropdown-menu {
      border-radius: 0;
      background-color: $secondary;
      a {
        color: #fff !important;
        &:hover {
          background-color: #4d515a;
        }
      }
    }
  }
  .navbar-light .navbar-toggler {
    border: none;
  }
}

/**
 * Footer
 */

footer {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: $secondary;
  margin-top: 15px;
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
  }

  .add-to-home {
    color: white;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    a {
      font-size: 14px;
      color: white;
      text-decoration: underline;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }

    #teconsent {
      margin-top: -1px;

      @media (min-width: 1200px) {
        margin-top: -3px;
      }
      @media (max-width: 992px) {
        margin-top: 0;
      }

      a {
        font-size: 14px;
        text-transform: lowercase;
      }
    }
  }

  .footer-version {
    display: flex;
    justify-content: center;
    margin-bottom: -28px;
    margin-top: 12px;
    span {
      display: inline;
      font-size: 10px;
      color: white;
      text-align: center;
      padding-right: 6px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1190px;
    }
  }
}

@media (max-width: 768px) {
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin-bottom: 25px;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.remote-consultations-with-libreview .video-container,
.remote-consultations-with-libreview-ie .video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 25px;
}

.remote-consultations-with-libreview .video-container iframe,
.remote-consultations-with-libreview-ie .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
 * Spinner
 */

#root > .loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.loader {
  .loader-spinner,
  .loader-spinner:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader-spinner {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0, 0, 0, 0.2);
    border-right: 1.1em solid rgba(0, 0, 0, 0.2);
    border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    transform: translateZ(0);
    animation: spinner 1.1s infinite linear;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

button {
  .loader {
    float: right;
    margin-left: 0.75rem;
    .loader-spinner {
      margin: 0px auto;
      width: 24px;
      height: 24px;
      border-width: 2px;
    }
  }
}

/**
 * WordPress
 */

.alignleft {
  margin: 0px auto 0px 0px;
  float: left;
  text-align: left;
}

.aligncenter {
  margin: 0px auto;
  text-align: center;
}

.alignright {
  margin: 0px 0px 0px auto;
  float: right;
  text-align: right;
}

.wp-caption {
  max-width: 100%;
}

/**
 * Other
 */

// taken from chrome user stylesheet, fixes missing arrows, description, back button from presentations in full screen view
:-webkit-full-screen {
  width: 100% !important;
}

.background-image-contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.background-image-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner {
  position: relative;
  min-height: 400px;
  color: #ffffff;
  padding: 70px 0px;
  margin-bottom: 30px;
  p:last-child {
    margin-bottom: 0px;
  }
}

.overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.5;
}

h1 {
  position: relative;
  font-family: 'BrandonGrotesque', sans-serif;
  font-size: 3.375rem;
  font-weight: 900;
  padding-bottom: 24px;
  margin-bottom: 26px;
  text-transform: uppercase;

  @include underline();
}

h2 {
  font-size: 2.25rem;
  font-style: italic;
  margin-bottom: 14px;
}

p {
  font-size: 1.125rem;
  margin-bottom: 26px;
}

.left-center {
  display: flex;
  justify-content: left;
  flex: 1 1 1;
  justify-content: center;
  flex-direction: column;
  //align-items: center;
}

.btn-orange {
  @include button-variant(#cf4520, #cf4520);
  margin-bottom: 10px;
}

.btn-white {
  @include button-variant(#ffffff, #ffffff);
  color: #ef4918;
  margin: 15px 0px;
}

/* Make the content with space between - used on case studies atm*/
.flex-space-between {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .banner {
    padding: 50px 0px !important;
    min-height: auto !important;
  }

  .header-top {
    img {
      width: 120px;
    }
  }
  h1 {
    font-size: 3.375rem;
  }
  .img-fluid {
    margin: 15px 0;
  }
}

/* For boxed radios and checkboxes */
.boxed-radio {
  &-row {
    float: left;
    padding-right: 0.28em;
  }
  div {
    overflow: hidden;
  }
  label {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #d1d3d4;
    font-weight: normal;
    padding-right: 0.6em;
  }
  input[type='radio'],
  input[type='checkbox'] {
    &:empty {
      display: none;
      ~ label {
        position: relative;
        line-height: 2.5em;
        text-indent: 2.6em;
        cursor: pointer;
        user-select: none;
        &:before {
          position: absolute;
          display: block;
          top: 0;
          bottom: 0;
          left: 0;
          content: '';
          width: 2em;
          background: #d1d3d4;
          border-radius: 3px 0 0 3px;
        }
      }
    }
    &:hover:not(:checked) ~ label {
      color: #888;
      &:before {
        content: '\2714';
        text-indent: 0.6em;
        color: #c2c2c2;
      }
    }
    &:checked ~ label {
      color: #777;
      &:before {
        content: '\2714';
        text-indent: 0.6em;
        color: #333;
        background-color: #ccc;
      }
    }
    &:focus ~ label:before {
      box-shadow: 0 0 0 3px #999;
    }
  }
  &-row {
    input[type='radio'],
    input[type='checkbox'] {
      &:checked ~ label:before {
        color: #fff;
        background-color: $tertiary;
      }
    }
  }
}

.alert {
  p {
    margin: 0;
  }
}

.customModal {
  max-width: 700px;
  font-weight: bold;
  color: #000;
  font-family: BrandonGrotesque;
  font-size: 20px;
  line-height: 22px;
  p {
    font-size: 20px;
    line-height: 22px;
  }
  h2 {
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
    font-style: normal;
    font-family: BrandonGrotesque !important;
  }
  .smaller-text {
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }
  a,
  h2 {
    color: #e4572d;
  }
  .popup-left-panel {
    width: 245px;
    float: left;
    height: 100%;
    img {
      margin: 0 !important;
    }
  }
  .popup-top {
    img {
      margin-top: 0px;
      display: block;
      height: 220px;
      width: 100%;
    }
  }
  .popup-right-panel {
    width: 455px;
    float: right;
    padding: 45px;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .popup-footer {
    font-size: 14px;
    font-weight: normal;
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 60px;
    margin-bottom: 10px;
  }
  .popup-close-btn {
    position: absolute;
    top: 15px;
    right: 22px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .orange-btn {
    background-color: #e4572d;
    color: white;
    font-size: 16px;
    line-height: 20px;
    padding: 13px 11px;
    border: 0;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    max-width: 390px;
    .popup-right-panel {
      width: 100%;
      padding: 0 20px 20px 20px;
    }
    h2 {
      font-size: 30px;
      line-height: 33px;
    }
    .popup-footer {
      position: relative;
    }
  }
}
